import { useEffect, useState } from 'react';
import { useCreateAdminLocation, useGetAdminLocation, useUpdateAdminLocation } from '@api/general.ts';
import { useValidation } from '@hooks';
import { z } from 'zod';
import { LoadingOptions, LoadingOptionsMap, LocationFormState, LocationStep } from '@types';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from '@components/CORE';

const defaultLocationState: LocationFormState = {
	locationName: '',
	address: '',
	locationType: [],
	openingTimes: {
		Monday: {
			start: '06:00',
			end: '18:00'
		},
		Tuesday: {
			start: '06:00',
			end: '18:00'
		},
		Wednesday: {
			start: '06:00',
			end: '18:00'
		},
		Thursday: {
			start: '06:00',
			end: '18:00'
		},
		Friday: {
			start: '06:00',
			end: '18:00'
		},
		Saturday: null,
		Sunday: null,
	},
	blackoutPeriods: [],
	timeSlotRequired: false,
	timeSlotProcessInfo: '',
	defaultDriverInstructions: '',
	restrictedTrucks: [],
	ppeInductionRequired: false,
	ppeInductionNotes: '',
	ppeRequired: false,
	ppeList: [],
	loadingOptions: Object.values(LoadingOptions).reduce((acc, key) => {
		acc[key] = null;
		return acc;
	}, {} as LoadingOptionsMap),
	callBeforeArrival: false,
	accessCardRequired: false,
	siteEntryRequirements: '',
	loadRestraints: [],
	dangerousGoodsAllowed: false,
	dangerousGoodsTypes: [],
	dangerousGoodsHandling: '',
	ownerCarrierId: null,
	ownerCarrierName: '',
	ownerShipperId: null,
	ownerShipperName: '',
}

export const useLocation = () => {
	const { id: locationID, route = LocationStep.DETAILS } = useParams();
	const {
		location,
		locationError,
		callGetLocation,
	} = useGetAdminLocation();

	const {
		loadingUpdateLocation,
		updateLocation,
		updateLocationError,
		callUpdateLocation,
	} = useUpdateAdminLocation();

	const {
		loadingCreateLocation,
		createLocation,
		createLocationError,
		callCreateLocation,
	} = useCreateAdminLocation();

	const navigate = useNavigate();

	const TimePeriodSchema = z.object({
		start: z.string().optional(),
		end: z.string().optional(),
	});

	interface TTimeRangeProps {
		start?: string;
		end?: string;
	}
	const isValidTimeRange = (props: TTimeRangeProps | null) => {
		if (!props) return true;
		const {
			start,
			end
		} = props;
		if (!start || !end) return false;
		const [startHour, startMinute] = start.split(':').map((val) => Number(val));
		const [endHour, endMinute] = end.split(':').map((val) => Number(val));

		const startTime = new Date();
		startTime.setHours(startHour, startMinute, 0, 0);

		const endTime = new Date();
		endTime.setHours(endHour, endMinute, 0, 0);

		return endTime > startTime;
	}


	const OpeningTimesSchema = z.object({
		Monday: TimePeriodSchema.nullable().optional().refine((data) => isValidTimeRange(data || null), { message: "Closing time can't be before opening time." }),
		Tuesday: TimePeriodSchema.nullable().optional().refine((data) => isValidTimeRange(data || null), { message: "Closing time can't be before opening time." }),
		Wednesday: TimePeriodSchema.nullable().optional().refine((data) => isValidTimeRange(data || null), { message: "Closing time can't be before opening time." }),
		Thursday: TimePeriodSchema.nullable().optional().refine((data) => isValidTimeRange(data || null), { message: "Closing time can't be before opening time." }),
		Friday: TimePeriodSchema.nullable().optional().refine((data) => isValidTimeRange(data || null), { message: "Closing time can't be before opening time." }),
		Saturday: TimePeriodSchema.nullable().optional().refine((data) => isValidTimeRange(data || null), { message: "Closing time can't be before opening time." }),
		Sunday: TimePeriodSchema.nullable().optional().refine((data) => isValidTimeRange(data || null), { message: "Closing time can't be before opening time." }),
	});


	const loadingOptionsSchema = z.object(
		Object.fromEntries(
			Object.values(LoadingOptions).map((key) => [key, z.boolean().nullable()])
		)
	);
	const useRouteSpecificValidation = () => {
		const detailValidation = {
			locationName: z.string().min(1, { message: 'Location name is required' }),
			address: z.string().min(1, { message: 'Address is required' }),
			locationType: z.array(z.string()).min(1, { message: 'Location Type is required' }),
			openingTimes: OpeningTimesSchema,
			blackoutPeriods: z.array(z.object({
				startDate: z.string(),
				endDate: z.string(),
			})).optional(),
			timeSlotRequired: z.boolean(),
			timeSlotProcessInfo: z.string().optional(),
			defaultDriverInstructions: z.string().optional(),
			restrictedTrucks: z.array(z.object({
				truckType: z.number(),
				truckSize: z.number(),
			})).optional(),
			ownerShipperId: z.number().nullable(),
			ownerCarrierId: z.number().nullable(),
		}
		const loadingValidation = {
			ppeInductionRequired: z.boolean().optional(),
			ppeInductionNotes: z.string().optional(),
			ppeRequired: z.boolean().optional(),
			loadingOptions: loadingOptionsSchema,
			loadRestraints: z.array(z.string()).optional(),
			dangerousGoodsAllowed: z.boolean(),
			dangerousGoodsHandling: z.string().optional()
		}
		return useValidation(route === LocationStep.LOADING ? loadingValidation : detailValidation);
	}

	const {
		formErrors,
		formValid,
		connect,
		clearErrors,
		updateValidationErrors
	} = useRouteSpecificValidation();

	const [formState, setFormState] = useState<LocationFormState>(defaultLocationState);
	const [navigateAfterSave, setNavigateAfterSave] = useState(true);

	const cleanRestrictedTrucks = (trucks: { truckType: number; truckSize: number; }[]) => {
		return trucks.filter((truck) => !(truck.truckType === 0 && truck.truckSize === 0));
	}

	const mapFormStateToPayload = (input: LocationFormState) => {
		return {
			driver_instruction: input.defaultDriverInstructions,
			induction_notes: input.ppeInductionNotes,
			blackout_periods: input.blackoutPeriods,
			location_type: input.locationType,
			name: input.locationName,
			opening_times: input.openingTimes,
			ppe_list: input.ppeList,
			require_induction: input.ppeInductionRequired,
			require_ppe: input.ppeRequired,
			require_time_slot: input.timeSlotRequired,
			time_slot_notes: input.timeSlotProcessInfo,
			address: input.address,
			restricted_trucks: cleanRestrictedTrucks(input.restrictedTrucks),
			loading_options: input.loadingOptions,
			call_before_arrival: input.callBeforeArrival,
			require_access_card: input.accessCardRequired,
			site_entry_requirements: input.siteEntryRequirements,
			load_restraints: input.loadRestraints,
			dg_allowed: input.dangerousGoodsAllowed,
			dg_types: input.dangerousGoodsTypes,
			dg_handling_instruction: input.dangerousGoodsHandling,
			owner_shipper_id: input.ownerShipperId,
			owner_carrier_id: input.ownerCarrierId
		};
	}

	useEffect(() => {
		if (locationID) callGetLocation({ routes: [locationID] });
	}, [locationID]);

	useEffect(() => {
		clearErrors();
		if (location) {
			setFormState((prevState) => {
				return {
					...prevState,
					ppeInductionRequired: location.require_induction,
					ppeInductionNotes: location.induction_notes ?? '',
					ppeRequired: location.require_ppe,
					ppeList: location.ppe_list ?? [],
					loadingOptions: { ...prevState.loadingOptions, ...location.loading_options },
					callBeforeArrival: location.call_before_arrival,
					accessCardRequired: location.require_access_card,
					siteEntryRequirements: location.site_entry_requirements ?? '',
					loadRestraints: location.load_restraints ?? [],
					dangerousGoodsAllowed: location.dg_allowed,
					dangerousGoodsTypes: location.dg_types ?? [],
					dangerousGoodsHandling: location.dg_handling_instruction ?? '',
					locationName: location.name,
					address: location.geo_address?.input_address,
					locationType: location.location_type,
					openingTimes: location.opening_times ?? prevState.openingTimes,
					blackoutPeriods: location.blackout_periods ?? [],
					timeSlotRequired: location.require_time_slot ?? false,
					timeSlotProcessInfo: location.time_slot_notes ?? '',
					defaultDriverInstructions: location.driver_instruction ?? '',
					restrictedTrucks: location.restricted_trucks ?? [],
					ownerCarrierId: location.owner_carrier_id,
					ownerCarrierName: location.owner_carrier_name ?? '',
					ownerShipperId: location.owner_shipper_id,
					ownerShipperName: location.owner_shipper_name ?? '',
				};
			});

		}
		if (locationError) {
			toast.error('Location not exists', { id: 'LOCATION_ERR_ID' });
		}
	}, [location, locationError]);
	const handleChange = (name: string, newValue: any): void => {
		setFormState((prevState) => {
			return {
				...prevState,
				[name]: newValue,
			};
		});
	};
	const handleSubmit = (navigateAfterSave: boolean = true) => {
		setNavigateAfterSave(navigateAfterSave);
		const isValid = formValid(formState);
		if (isValid.success) {
			const payload = mapFormStateToPayload(formState)
			if (locationID) {
				callUpdateLocation({
					routes: [`${locationID}`],
					payload: payload
				});
			} else {
				callCreateLocation({ payload: payload })
			}
		}
	}
	useEffect(() => {
		if (createLocation) {
			navigateAfterSave && navigate(`/configuration/locations/${createLocation.id}/${LocationStep.LOADING}`)
		}
		if (createLocationError) {
			toast.error(createLocationError?.response?.data?.message ?? 'An error occured trying to create location', { id: 'LOCATION_ERR_ID' });
		}

		if (updateLocation) {
			navigateAfterSave && navigate('/configuration/locations/');
			toast.success('Location has been updated', { id: 'LOCATION_SUCCESS_ID' });
		}

		if (updateLocationError) {
			toast.error('An error occured trying to update location', { id: 'LOCATION_ERR_ID' });
		}
	}, [createLocation, createLocationError, updateLocation, updateLocationError]);

	useEffect(() => {
		if (formErrors.length > 0) {
			const [firstErrorEntry] = formErrors;

			const errorElement = document.querySelector(`[name="${firstErrorEntry.field}"]`);
			if (errorElement) {
				errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

				const focusableElement = errorElement as HTMLElement;
				if (focusableElement && typeof focusableElement.focus === 'function') {
					setTimeout(() => {
						if (focusableElement.getAttribute('name') !== 'address') {
							focusableElement.focus();
						}
					}, 500);
				}
			}
		}

	}, [formErrors]);


	return {
		locationID,
		route,
		location,
		formState,
		loadingCreateLocation,
		loadingUpdateLocation,
		handleChange,
		handleSubmit,
		connect,
		updateValidationErrors
	};
};

export default useLocation;
type IconProps = {
    size?: number;
    color?: string;
}

export const DownloadIcon = (props:IconProps) => {
	const { size = 24, color = '#D9D9D9' } = props;
	return (
		<svg role={'img'} width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<mask id='mask0_699_9766' style={{ 'maskType': 'alpha' } as React.CSSProperties} maskUnits='userSpaceOnUse' x='0' y='0' width={size} height={size}>
				<rect width={size} height={size} fill={color}/>
			</mask>
			<g mask='url(#mask0_699_9766)'>
				<path d='M4.33341 13.3333C3.3223 13.3333 2.45841 12.9833 1.74175 12.2833C1.02508 11.5833 0.666748 10.7278 0.666748 9.71667C0.666748 8.85 0.927859 8.07778 1.45008 7.4C1.9723 6.72223 2.65564 6.28889 3.50008 6.1C3.68897 5.3 4.16119 4.53889 4.91675 3.81667C5.6723 3.09445 6.47786 2.73334 7.33342 2.73334C7.70008 2.73334 8.01397 2.86389 8.27508 3.125C8.53619 3.38612 8.66675 3.7 8.66675 4.06667V8.1L9.73342 7.06667L10.6667 8L8.00008 10.6667L5.33342 8L6.26675 7.06667L7.33342 8.1V4.06667C6.48897 4.22223 5.83342 4.63056 5.36675 5.29167C4.90008 5.95278 4.66675 6.63334 4.66675 7.33334H4.33341C3.68897 7.33334 3.13897 7.56112 2.68341 8.01667C2.22786 8.47223 2.00008 9.02223 2.00008 9.66667C2.00008 10.3111 2.22786 10.8611 2.68341 11.3167C3.13897 11.7722 3.68897 12 4.33341 12H12.3334C12.8001 12 13.1945 11.8389 13.5167 11.5167C13.839 11.1944 14.0001 10.8 14.0001 10.3333C14.0001 9.86667 13.839 9.47223 13.5167 9.15C13.1945 8.82778 12.8001 8.66667 12.3334 8.66667H11.3334V7.33334C11.3334 6.8 11.2112 6.30278 10.9667 5.84167C10.7223 5.38056 10.4001 4.98889 10.0001 4.66667V3.11667C10.8223 3.50556 11.4723 4.08056 11.9501 4.84167C12.4279 5.60278 12.6667 6.43334 12.6667 7.33334C13.4334 7.42223 14.0695 7.75278 14.5751 8.325C15.0806 8.89723 15.3334 9.56667 15.3334 10.3333C15.3334 11.1667 15.0417 11.875 14.4584 12.4583C13.8751 13.0417 13.1667 13.3333 12.3334 13.3333H4.33341Z' fill='#1C1B1F'/>
			</g>
		</svg>

	);
}

import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { ShipperIcon } from '@img/icons';
import { GavelIcon, LocationOnIcon } from '@img/icons';
import { navigator } from '@types';
import ListAltIcon from '@mui/icons-material/ListAlt';

const configNav = () => {
	const eosRoot = `/admin/configuration`;
	const root = `/configuration`;

	return [
		{
			label: 'Dashboard',
			icon: (color:string) => <DashboardIcon style={{ color: color }} />,
			link: `${root}/dashboard`,
			enabled: true,
			activeURL: 'dashboard'
		},
		{
			label: 'Admin Rate Cards',
			icon: (color:string) => <PriceChangeOutlinedIcon style={{ color: color }} />,
			link: '/admin/configuration/ratecards',
			activeURL: 'ratecards',
			domain: 'eos'
		},
		{
			label: 'Regions',
			icon: (color:string) => <MapOutlinedIcon style={{ color: color }} />,
			link: `${eosRoot}/region`,
			activeURL: 'region',
			domain: 'eos'
		},
		{
			label: 'Locations',
			icon: (color: string) => <LocationOnIcon color={color}/>,
			link: `${root}/locations`,
			enabled: true,
			activeURL: 'locations'
		},
		{
			label: 'Truck Types',
			icon: (color:string) => <ListAltIcon style={{ color: color }} />,
			link: `${eosRoot}/trucktypes`,
			activeURL: 'truck_types',
			domain: 'eos'
		},
		{
			label: 'Registered Shippers',
			icon: (color:string) => <ShipperIcon width={22} height={22} color={color} />,
			link: `${root}/registered-shippers`,
			enabled: true,
			activeURL: 'registered-shippers'
		},
		{
			label: 'Registered Carriers',
			icon: (color:string) => <LocalShippingOutlinedIcon style={{ color: color }} />,
			link: `${root}/registered-carriers`,
			enabled: true,
			activeURL: 'registered-carriers'
		},
		{
			label: 'Fuel Levy',
			icon: (color:string) => <LocalGasStationOutlinedIcon style={{ color: color }} />,
			link: `${root}/fuel-levy`,
			enabled: true,
			activeURL: 'fuel-levy'
		},
		{
			label: 'Marketplace',
			icon: (color:string) => <GavelIcon color={color} />,
			link: `${root}/marketplace`,
			activeURL: 'marketplace',
			domain: 'eos'
		},
		{
			label: 'Company',
			icon: (color:string) => <StoreOutlinedIcon style={{ color: color }} />,
			link: `${eosRoot}/company`,
			activeURL: 'company',
			domain: 'eos'
		}
	] as navigator[];
}

export default configNav;

type TVals = {
	value?: any;
	suffix?: string;
	suffixSingular?: string;
	prefix?: string;
}

export const combineValues = (values: TVals[]): string => {
	return values.map((value: TVals) => {
		if (!value.value) {
			return null;
		}
		return [value.prefix, value.value, value.suffix]
			.filter((v) => !!v)
			.join(' ')
	})
		.filter(((v: null | string) => !!v))
		.join(' | ')
}

import { useState, useEffect, ChangeEvent } from 'react';
import styles from '../FilterOptions.module.scss';
import { Checkbox, Text, Spacer } from '@components';
import { TEXT_WEIGHT } from '@types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type FilterSelectMultipleGroupProps = {
    header: any;
    meta: any;
    filterData: any;
    onChange: (val:any) => void;
}

type TOption = {
	name?: string;
	label?: string;
	value?: any;
	checked?: boolean;
}

type TFilterOpts = {
	name: string;
	options: TOption[];
}

export const FilterSelectMultipleGroup = (props:FilterSelectMultipleGroupProps) => {
	const {
		header,
		meta,
		filterData,
		onChange
	} = props;
	const [checkOptions, setCheckOptions] = useState<TFilterOpts[]>([]);
	const [accordionExpanded, setAccordionExpanded] = useState(false);
	const [accordionExpandedSubGroup, setAccordionExpandedSubGroup] = useState({
		index: -1,
		expand: false
	});
	const fullName = meta?.columnFullName;
	const filterOptions = meta?.filterOptions?.options ?? [];
	const getFilterValues = (data: TFilterOpts[]) => {
		const resultValues: any[] = [];
		data.forEach((event) => {
			event.options?.forEach((option: TOption) => {
				if (option.checked) {
					resultValues.push(option.value);
				}
			});
		});
		return resultValues
	}
	const changeCheckValue = (e: ChangeEvent<HTMLInputElement>) => {
		const newOpts = [...checkOptions]?.map((optGroup: TFilterOpts) => {
			return {
				...optGroup,
				options: optGroup.options.map((opt: TOption) => {
					let val: any = e.target.value;
					if (typeof opt.value === 'number') {
						val = parseInt(e.target.value);
					}
					let { checked } = opt;
					if (opt.label === e.target.name && opt.value === val) checked = e.target.checked; //eslint-disable-line
					return {
						...opt,
						checked: checked
					}
				})
			};
		});
		setCheckOptions(newOpts);
		const filterResult = getFilterValues(newOpts);
		const newFilterData = { ...filterData };
		newFilterData.value = filterResult.join(', ');
		newFilterData.columnField = meta?.databaseLocation;
		if (meta.operatorValue) {
			newFilterData.operatorValue = meta.operatorValue;
		} else {
			newFilterData.operatorValue = filterResult.length > 1 ? 'In' : 'Is';
		}
		onChange(newFilterData);
	}

	useEffect(() => {
		if (filterData?.value) {
			const checkedValues = filterData.value.split(', ');
			const newOpts = [...filterOptions]?.map((category: TFilterOpts) => {
				return {
					...category,
					options: category.options.map((option) => {
						return {
							...option,
							checked: checkedValues.includes(option.value)
						}
					})
				};
			});
			setCheckOptions(newOpts);
		} else {
			const newOpts = [...filterOptions]?.map((category: TFilterOpts) => {
				category?.options?.forEach((option: TOption) => {
					if (option.checked) {
						option.checked = false;
					}
				});
				return category;
			});
			setCheckOptions(newOpts);
		}
	}, [meta, filterData]);
	return (
		<>
			<div className={styles.filterLabel}>
				<Text type={'label'} weight={TEXT_WEIGHT.BOLD} text={fullName !== undefined ? fullName : header} />
				<button
					onClick={() => {
						setAccordionExpanded(!accordionExpanded);
					}}
					className={`${styles.accordionButton} ${accordionExpanded ? styles.flipArrow : ''}`}
					aria-label={'Set Accordion Expanded'}
				>
					<KeyboardArrowDownIcon />
				</button>
			</div>
			{accordionExpanded && (
				<div className={styles.inset}>
					{filterOptions.map((note: TFilterOpts, index: number) => (
						<>
							<div key={index}>
								{' '}
								<div className={styles.multipleGroupBox}>
									<Text type={'label'} weight={TEXT_WEIGHT.BOLD} text={note.name} />
									<button
										onClick={() => {
											setAccordionExpandedSubGroup((prevState) => ({
												...prevState,
												index: index,
												expand: index === prevState.index ? !prevState.expand : true
											}));
										}}
										className={`${styles.accordionButton} ${
											accordionExpandedSubGroup.index === index && accordionExpandedSubGroup.expand
												? styles.flipArrow
												: ''
										}`}
										aria-label={'Set Sub Group Accordion Expanded'}
									>
										<KeyboardArrowDownIcon />
									</button>
								</div>
								{accordionExpandedSubGroup.index === index && accordionExpandedSubGroup.expand && (
									note?.options && note?.options.map((checkbox: TOption, i:number) => {
										return <Checkbox
											key={i}
											label={checkbox.label}
											value={checkbox.value}
											name={checkbox.name ?? ''}
											onChange={(e: any) => {
												changeCheckValue(e);
											}}
											checked={checkbox.checked ?? false}
										/>
									})
								)}
							</div>
							<Spacer size={10} />
						</>
					))}
				</div>
			)}
		</>
	);
}
type IconProps = {
	width?: number;
	height?: number;
	color?: string;
}

export const PODAwaitingVerificationIcon = (props:IconProps) => {
	const {
		width = 18,
		height = 18,
		color = 'var(--func-yellow-dark)'
	} = props;
	return (
		<svg role={'img'} width={width} height={height} viewBox={'0 0 18 18'} xmlns={'http://www.w3.org/2000/svg'}>
			<path
				d='M3.75 3V7.51875V7.5V15V3ZM5.25 10.5H7.875C7.9875 10.2125 8.125 9.94375 8.2875 9.69375C8.45 9.44375 8.6375 9.2125 8.85 9H5.25V10.5ZM5.25 13.5H7.63125C7.56875 13.25 7.52813 13 7.50938 12.75C7.49063 12.5 7.49375 12.25 7.51875 12H5.25V13.5ZM3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39688 15.7656 2.25 15.4125 2.25 15V3C2.25 2.5875 2.39688 2.23438 2.69063 1.94063C2.98438 1.64688 3.3375 1.5 3.75 1.5H9.75L14.25 6V7.875C14.0125 7.775 13.7688 7.69688 13.5188 7.64062C13.2688 7.58437 13.0125 7.54375 12.75 7.51875V6.75H9V3H3.75V15H8.26875C8.46875 15.3 8.69375 15.5781 8.94375 15.8344C9.19375 16.0906 9.46875 16.3125 9.76875 16.5H3.75ZM12.375 14.25C12.9 14.25 13.3438 14.0687 13.7063 13.7062C14.0688 13.3438 14.25 12.9 14.25 12.375C14.25 11.85 14.0688 11.4062 13.7063 11.0438C13.3438 10.6813 12.9 10.5 12.375 10.5C11.85 10.5 11.4062 10.6813 11.0438 11.0438C10.6813 11.4062 10.5 11.85 10.5 12.375C10.5 12.9 10.6813 13.3438 11.0438 13.7062C11.4062 14.0687 11.85 14.25 12.375 14.25ZM16.2 17.25L14.175 15.225C13.9125 15.4 13.6281 15.5313 13.3219 15.6188C13.0156 15.7063 12.7 15.75 12.375 15.75C11.4375 15.75 10.6406 15.4219 9.98438 14.7656C9.32812 14.1094 9 13.3125 9 12.375C9 11.4375 9.32812 10.6406 9.98438 9.98438C10.6406 9.32812 11.4375 9 12.375 9C13.3125 9 14.1094 9.32812 14.7656 9.98438C15.4219 10.6406 15.75 11.4375 15.75 12.375C15.75 12.7 15.7063 13.0156 15.6188 13.3219C15.5313 13.6281 15.4 13.9125 15.225 14.175L17.25 16.2L16.2 17.25Z'
				fill={color}
			/>
		</svg>
	);
};
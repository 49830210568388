import { Text, Button, H0 } from '@components';
import styles from './ErrorFallback.module.scss';
import { BUTTON_TYPES } from '@types';
import BrokenTruckImage from '@img/broken-truck.png';

export const ErrorFallback = () => {
	const handleBackClicked = () => {
		window.history.back();
	};

	return (
		<div className={styles.errorFallbackContainer}>
			<div className={styles.errorMessageSection}>
				<H0>Oops something went wrong</H0>
				<Text>There was a problem processing your request. Please try again.</Text>
			</div>
			<img alt={'Broken truck'} src={BrokenTruckImage} />
			<Button onClick={handleBackClicked} type={BUTTON_TYPES.SECONDARY} text='Return to last page' />
		</div>
	);
}
import { DATA_UPDATE_MODE, TTableSortModel } from '@types';

type Props = {
    unique: boolean;
    tableID: string;
    dataUpdateMode: DATA_UPDATE_MODE;
    currentPage: number | undefined;
    rowsPerPage: number | undefined;
    sortModel: TTableSortModel[];
    searchFromParent: string;
    loadedFilter: any;
    matrixId: number | string;
}

const GenerateURL = (props: Props):string => {
	const {
		unique,
		tableID,
		dataUpdateMode,
		currentPage,
		rowsPerPage,
		sortModel,
		searchFromParent,
		loadedFilter,
		matrixId
	} = props;
	const locSplit = window.location.href.split('?');
	let [URL] = locSplit;
	const queryString = locSplit[1] || '';
	const queryParts = queryString.split('&');
	let customerID = '';
	for (let i=0, l=queryParts.length; i<l; ++i) {
		const part = queryParts[i].split('=');
		if (part[0].toLowerCase() === 'sid' || part[0].toLowerCase() === 'cid') {
			customerID = queryParts[i];
		}
	}
	const queryArr = [];
	if (customerID !== '') {
		queryArr.push(customerID);
	}
	if (unique) queryArr.push(`tableId=${tableID}`);
	if (currentPage) {
		queryArr.push(`page=${currentPage}`);
	} else {
		queryArr.push(`page=1`);
	}
	if (rowsPerPage) {
		queryArr.push(`perPage=${rowsPerPage}`);
	} else {
		queryArr.push(`perPage=25`);
	}
	if (searchFromParent !== '') {
		if (dataUpdateMode === DATA_UPDATE_MODE.SERVER) {
			queryArr.push(`filter[search]=${encodeURIComponent(searchFromParent)}`);
		} else {
			queryArr.push(`search=${encodeURIComponent(searchFromParent)}`);
		}
	}
	if (matrixId) {
		queryArr.push(`matrixID=${matrixId}`);
	}
	if (loadedFilter && loadedFilter.linkOperator) {
		if (loadedFilter.filterID) queryArr.push(`filterID=${loadedFilter.filterID}`);
		const filterItems = loadedFilter.items;
		const link = loadedFilter.linkOperator;
		for (let i=0, l=filterItems.length; i<l; ++i) {
			if (filterItems[i].isCustomPartialExpired) {
				queryArr.push(`expired=${encodeURIComponent(filterItems[i].value)}`);
			} else if (filterItems[i].isAddress) {
				if (filterItems[i].pickup) queryArr.push(`filter[lane][${i}][pickup]=${encodeURIComponent(filterItems[i].pickup)}`);
				if (filterItems[i].dropoff) queryArr.push(`filter[lane][${i}][dropoff]=${encodeURIComponent(filterItems[i].dropoff)}`);
				queryArr.push(`filter[lane][${i}][both]=${encodeURIComponent(filterItems[i].both)}`);
			} else {
				queryArr.push(`filter[${link}][${i}][columnField]=${encodeURIComponent(filterItems[i].columnField)}`);
				queryArr.push(`filter[${link}][${i}][operatorValue]=${encodeURIComponent(filterItems[i].operatorValue)}`);
				queryArr.push(`filter[${link}][${i}][value]=${encodeURIComponent(filterItems[i].value)}`);
			}
		}
	}
	if (sortModel.length) {
		/*
		 *   Can only sort by one column at the moment...
		 *   Selecting first column in array to be sorted on...
		 *   Will come back latter and see if we can facilitate
		 *   Multi-Sort in the BackEnd...
		 */
		const [sort] = sortModel;
		queryArr.push(`sort=${sort?.desc ? '-' : ''}${sort?.value}`);
	}
	const query = queryArr.join('&');
	URL = `${URL}?${query}`;
	return URL;
}

export default GenerateURL;

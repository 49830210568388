import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useValidation } from '@hooks';
import { useGetAdminLocationConnectedShippers, usePostAdminLocationConnectedShippers } from '@api/general.ts';
import { useNavigate } from 'react-router-dom';

type Shipper = {
	bus_name: string;
	company_name: string;
	shipper_id: number|null;
	trade_name: string;
}

const validationSchema = z.array(
	z.object({
		shipper_id: z.number({
			required_error: 'Please select a shipper',
			invalid_type_error: 'Please select a Shipper',
		}).or(z.string()).pipe(z.coerce.number({
			required_error: 'Please select a shipper',
			invalid_type_error: 'Please select a Shipper',
		}))
	})
);
export const useConnectShippers = (locationId: string) => {
	const {
		loading,
		connectedShippers,
		callGetConnectedShippers,
	} = useGetAdminLocationConnectedShippers();
	const navigate = useNavigate();
	const [isDirty, setIsDirty] = useState(false);

	const {
		loading: saving,
		postConnectedShippers
	} = usePostAdminLocationConnectedShippers();

	const [shippers, setShippers] = useState<Shipper[]>([]);
	const {
		formErrors,
		formValid,
		connectPath,
		clearErrors
	} = useValidation({ form: validationSchema });

	useEffect(() => {
		callGetConnectedShippers({ routes: [locationId] });
	}, [locationId]);

	useEffect(() => {
		if (connectedShippers.length) {
			setShippers(connectedShippers);
		}
	}, [connectedShippers]);
	const onCancel = () => {
		navigate( `/configuration/locations`);
	}
	return {
		addShipper: () => {
			const newShipper: Shipper = {
				bus_name: '',
				company_name: '',
				shipper_id: null,
				trade_name: ''
			};
			setShippers([...shippers, newShipper]);
			setIsDirty(true);
		},
		removeShipper: (index: number) => {
			setShippers(shippers.filter((_, i) => i !== index));
			setIsDirty(true);
		},
		handleChange: (e: any, index: number) => {
			const {
				name,
				value
			} = e.target;
			const updatedShippers = shippers.map((shipper, i) =>
				i === index ? {
					...shipper,
					[name]: value
				} : shipper
			);
			setShippers(updatedShippers);
			setIsDirty(true);
		},
		handleSubmit: (navigateAfterSave: boolean = true) => {
			const valid = formValid({ form: shippers });
			if (valid.success) {
				setIsDirty(false);
				clearErrors();
				postConnectedShippers({ routes: [locationId], payload: { form: shippers } });
				navigateAfterSave && navigate( `/configuration/locations`);
			}
		},
		connectPath,
		formErrors,
		shippers,
		loading,
		saving,
		isDirty,
		onCancel
	}
}

export default useConnectShippers;